<template>
    <section>
        <loading v-bind:dialog="isLoading"></loading>
        <alert v-bind:tipo="notificacion.tipo" v-bind:mensaje="notificacion.mensaje"></alert>

        <v-card v-show="!errorIDITEM">
            <div>
                <v-btn icon slot="activator"
                @click="Volver(id,'Historico')">
                <v-icon >arrow_back</v-icon>
                </v-btn>
            </div>
            <v-toolbar card color="white">
                <v-text-field class="title-field" solo disabled :label="'Código de cliente: ' + id"  hide-details></v-text-field> <!--flat solo-->
            </v-toolbar>

            <v-form class="formulario" @submit.prevent="submitForm">
                <v-container>
            
            <div class="contenedor">
               
                <div class="col">
                                <!-- FECHA -->
                                <v-menu v-model="datePickerOpen" :close-on-content-click="false" offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="input" v-model="fecha" :rules="[rules.required, rules.fecha, rules.fecha_cant_valida]" label="Fecha" :readonly="true" v-on="on"
                                            required></v-text-field>
                                    </template>
                                    <v-date-picker v-model="fecha" no-title @input="datePickerOpen = false"></v-date-picker>
                                </v-menu>

                                <!-- TIPO -->
                                <v-select v-model="estado" class="input" :items="Estados" :rules="[ rules.required, rules.estado]" item-text="desc" item-value="id" label="Estado" required></v-select>
                                
                                <!-- item-text define la propiedad del objeto que se usará como etiqueta visible, y item-value define la propiedad que se usará como valor seleccionado. -->
                                <v-select class="input" v-model="tipo" :rules="[rules.required, rules.tipo, rules.tipoValido]" :items="TiposDeHistoricos" item-text="thc_descri" item-value="thc_codigo" label="Tipo" required></v-select>
            </div>
            <v-textarea class="desc" v-model="descripcion" :rules="[rules.required]" label="Descripción" required></v-textarea>
            </div>
                <div class="botones">
                        <!-- Boton Cancelar-->
                        <v-btn class="ml-auto  enviar" color="secondary" @click="Volver(id,'Historico')">Cancelar</v-btn>
                        <!-- Botón al final del formulario -->
                        <v-btn class="ml-auto  enviar" color="primary" type="submit">Guardar</v-btn>
                </div>    
            
                </v-container>
            </v-form>
        </v-card>
    </section>
</template>

<script>
import Historico from "@/services/HistoricoServices";
import TipoHistorico from "@/services/TipoHistorico";
import ClienteService from "@/services/ClienteServices";
import loading from "@/components/share/Loading.vue";
import alert from "@/components/alert/alert.vue";

export default {
    props: {
        id: {
            type: Number,
            require: true
        },
        item:{
            type: Number,
            require: true
        }
    },
    data() {
        return {
            errorIDITEM: false,
            TiposDeHistoricos:[],
            esTipoValido(tipo){
                setTimeout(() => {
                const tipos = this.TiposDeHistoricos.map(tipo => tipo.thc_codigo)
                if(!tipos.includes(tipo)){
                    return "Tipo no valido";
                }
                return true
                },1000)
            },
            Estados: [{id: 1, desc:'Activo'}, {id: 9, desc:'Inactivo'}],
            isLoading: false,
            notificacion: { mensaje: "", tipo: "error" },
            //calendario
            fecha: null,
            datePickerOpen: false,
            codigoCliente: null,
            descripcion: '',
            estado: '',
            tipo: '',
            //validaciones del vuetify
            rules:{
                length: len => v => (v || '').length >= len || `Descripcion no valida`,
                required: v => !!v || 'Este campo es requerido',
                estado: v => (isNaN(v) || (v !== 1 && v!== 9)) ? 'Estado no valido' : true,
                tipo: v => (isNaN(v) || v < 0) ? 'Tipo no valido' : true,
                fecha: v => (isNaN(Date.parse(v))) ? 'Fecha no valida' : true,
                fecha_cant_valida: v =>( /\d\d\d\d-\d\d-\d\d/g.test(v) ) || 'Fecha no valida',
                tipoValido: async(v) => (await this.esTipoValido(v)) || 'Tipo no valido'
            }  
        };
    },
    methods: {
        Volver(id,nombreRuta){
            this.$router.push({name: nombreRuta, params:{id: id}});
        },
        async submitForm() {
            // Aca podes realizar acciones con los datos del formulario,
            // como enviarla a la api, realizar validaciones, etc.

// --------------------------------- VALIDACIONES -------------------------------------------------------------------------------------------------------
            
            //se pasa a numero el tipo y el estado
            this.tipo = parseInt(this.tipo)
            this.estado = parseInt(this.estado)
            console.log(this.tipo, this.fecha, this.descripcion, this.estado)
            // VALIDAR QUE LOS CAMPOS INGRESADOS SEAN DEL TIPO VALIDO.
            if ( (this.id < 0 ||this.item < 0 ) || (isNaN(this.id) || isNaN(this.item < 0 )) ){
                this.notificacion.mensaje = "Inserte ID o ITEM valido";
                this.isLoading=false
                this.errorIDITEM=true
                return
            }

            //valido si algun campo es nulo:
            if(this.item === null) {
                //muestro el mensaje y vuelvo al form
                this.notificacion.mensaje = "Item no valido";
                return
            }
            if(this.tipo === null){
                //muestro el mensaje y vuelvo al form
                this.notificacion.mensaje = "Tipo no valido";
                return
            } 
            if(this.fecha === null){
                //muestro el mensaje y vuelvo al form
                this.notificacion.mensaje = "Fecha no valida";
                return
            }
            if(this.descripcion === null) {
                //muestro el mensaje y vuelvo al form
                this.notificacion.mensaje = "Descripcion no valida";
                return
            }
            if(this.estado === null){
                //muestro el mensaje y vuelvo al form
                this.notificacion.mensaje = "Estado no valido";
                return
            }

            //valido si algun campo es vacio o no valido
            if(isNaN(this.tipo)){
                //muestro el mensaje y vuelvo al form
                this.notificacion.mensaje = "Tipo no valido";
                return
            }
            if (this.descripcion === ''){
                //muestro el mensaje y vuelvo al form
                this.notificacion.mensaje = "Descripcion no valida";
                return
            }
            if(isNaN(this.estado)){
                //muestro el mensaje y vuelvo al form
                this.notificacion.mensaje = "Estado no valido";
                return
            }

            if(this.estado !== 1 && this.estado !== 9){
                //muestro el mensaje y vuelvo al form
                this.notificacion.mensaje = "Complete los campos necesarios";
                return
            }

            //valido si el tipo esta en la lista de tipos
            const tipos = this.TiposDeHistoricos.map(tipo => tipo.thc_codigo)
            if(!tipos.includes(this.tipo)){
                //muestro el mensaje y vuelvo al form
                this.notificacion.mensaje = "Tipo no valido";
                return
            }
            //valido si la fecha es una fecha valida
            const [ , mes, dia] = this.fecha.split('-')
            if (!/\d{2}/g.test(mes)) {
                this.notificacion.mensaje =  'Mes no valido'
                return
            }
            if (!/\d{2}/g.test(dia)) {
                this.notificacion.mensaje =  'Dia no valido'
                return
            }
            //paso el mes a numero
            const mesNumero = parseInt(mes)
            //paso el dia a numero
            const diaNumero = parseInt(dia)
            if(mesNumero < 1 || mesNumero > 12){
                //muestro el mensaje y vuelvo al form
                this.notificacion.mensaje = "Fecha no valida";
                return
            }
            if(diaNumero < 1 || diaNumero > 31){
                //muestro el mensaje y vuelvo al form
                this.notificacion.mensaje = "Fecha no valida";
                return
            }

// --------------------------------- FIN VALIDACIONES -------------------------------------------------------------------------------------------------------
            try {
                //Envio a la api
                await Historico.Editar({
                    cl2_codigo: this.id,
                    cl2_item: this.item,
                    cl2_codthc: this.tipo,
                    "cl2_fecha": this.fecha,
                    "cl2_descri": this.descripcion,
                    "cl2_estado": this.estado
                    })
            } catch (error) {
                //si hay error algo
                this.notificacion.mensaje = "Ocurrio un error al intentar recuperar los registros.";
                this.isLoading=false
                this.errorIDITEM=true
            }

            //sino volver
            this.Volver(this.id,'Historico')
        },
        async Cargar(){
            this.errorIDITEM=false
            this.isLoading = true;
            const curcliente = await ClienteService.getbyID(this.id);
            if ((curcliente.cli_codigo < 1 || curcliente === '') || (this.item < 0 )){
                this.notificacion.mensaje = "Inserte ID o ITEM valido";
                this.isLoading=false
                this.errorIDITEM=true
                return
            }
            this.notificacion.mensaje=''
            
            await Historico
            .consultar(this.id)
            .then( async(res=[]) => {
                const historico= res.find(e=>e.cl2_item === this.item)
                //validacion de si encontro o no
                if( historico === undefined ){
                    this.notificacion.mensaje = "Inserte ITEM valido";
                    this.isLoading=false
                    this.errorIDITEM=true
                    return
                }
                else{
                    const fecha = new Date(historico.cl2_fecha);
                    const dia = fecha.getDate().toString().padStart(2, '0');
                    const mes= fecha.getMonth()+1
                    const anio= fecha.getFullYear()
                    // anio-mes-dia
                    this.fecha= `${anio}-${mes.toString().padStart(2, '0')}-${dia}`
                    this.estado= historico.cl2_estado
                    this.tipo= historico.cl2_codthc
                    this.descripcion= historico.cl2_descri
                }
                this.isLoading = true;
                //consultar tipo de historico
                await TipoHistorico
                .consultar()
                .then((res) => {
                    this.TiposDeHistoricos = res
                })
            })
            .catch((err) => {
                this.notificacion.mensaje = "Ocurrio un error al intentar recuperar los registros.";
                if (err.response && err.response.data.hasOwnProperty("errorMessage")){
                    this.notificacion.mensaje = err.response.data.errorMessage
                }})
            .finally(() => {
                this.isLoading = false
            })
        },
    },
    components:{
        loading, alert
    },
    created() {
        this.Cargar()
    },
    watch:{
        id(){
           this.Cargar()
        },
        item(){
           this.Cargar()
        },
    }

}
</script>


<style scoped>
.col{
    display: flex;
    flex-direction: row;
}
.input{
    padding: 16px;
}
.contenedor{
    display: flex;
    flex-direction: column;
}
.desc{
    display: block;
    padding: 16px;
    width: 100%;
}

.enviar{
    margin-bottom: 30px;
    margin-top: 30px;
    width: 50%;
    display: flex;
}
.title-field {
    font-weight: bold;
    background-color: transparent;
    border: none;
    padding: 0;
}
.botones{
    display: flex;
    padding: 16px;
}
</style>